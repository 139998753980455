import React from "react"
import { Helmet } from "react-helmet"

import OfferBase from "../../../components/offer"

const Offer = () => {
  return (
    <OfferBase offerTitle={'Stomatologia dziecięca'}>
      <Helmet title={"Stomatologia dziecięca - Klinika stomatologiczna Dent&Beauty"} />
    </OfferBase>
  )
}

export default Offer
